<!--
 * @Description: 阅读感悟页面
 * @Author: JunLiangWang
 * @Date: 2023-02-22 16:54:24
 * @LastEditors: JunLiangWang
 * @LastEditTime: 2023-08-02 00:33:54
-->
<template>
  <div class="project-container">
    <header class="slogn">
      <p>{{ bookData[bookIndex].reviews[reviewsIndex].abstract }}</p>
      <p style="text-align: end;">《{{ bookData[bookIndex].title }}》</p>
    </header>
    <div class="book-list-container">
      <div v-for="item, index in bookData" :key="index">
        <div class="book-item" @click="$router.push({path:'/bookDetail',query:{bookIndex:index}})">
          <img :src="item.cover" />
          <span class="book-cover-border" />
        </div>
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import bookData from '@/config/book/index.js'
export default {
  name: "book",
  data() {
    return {
      bookData,
      bookIndex: 0,
      reviewsIndex: 0
    }
  },
  mounted() {
    this.bookIndex = Math.floor(Math.random() * bookData.length)
    this.reviewsIndex = Math.floor(Math.random() * bookData[this.bookIndex].reviews.length)
  }
}
</script>

<style lang="scss" scoped>
.project-container {
  max-width: 1080px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  padding: 0 25px;
  margin-bottom: 40px;
}

.slogn {
  
  margin-top: 25px;
    margin-bottom: 50px;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-size: 1em;
    opacity: 0.6;
    line-height: 1.5;
    margin: 10px 0;
  }

  p:first-child {
    margin-top: 0;
  }
}

.book-list-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  margin-top: 25px;
  width: 100%;

  .book-item {
    position: relative;
    cursor: pointer;
    line-height: 0;

    img {
      width: 100%;
    }

  }

  .book-item:hover {

    transform: translateY(-10px);
  }

  p {
    text-align: center;
    margin-top: 10px;
    margin: 10px 0 0 0;
    font-size: 1em;
  }
}

.book-cover-border {
  background: transparent;
  background-image: linear-gradient(90deg, hsla(0, 0%, 63.1%, .25), rgba(21, 21, 20, .1) 1%, hsla(0, 0%, 100%, .15) 4%, hsla(0, 0%, 58%, .1) 8%, hsla(0, 0%, 89%, 0) 57%, rgba(223, 218, 218, .03) 91%, rgba(223, 218, 218, .05) 98%, hsla(0, 0%, 100%, .1));
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, .1);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

@media only screen and (max-width: 900px) {
  .book-list-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 3vw;
    grid-row-gap: 3vw;
  }

  .slogn {
    p {
      -webkit-line-clamp: 4;
    }
  }
}

@media only screen and (max-width: 650px) {
  .book-list-container {
    display: grid;
    grid-column-gap: 5vw;
    grid-row-gap: 5vw;
    grid-template-columns: repeat(3, 1fr);

    .book-item {
      figcaption {
        font-size: 2.5vw;
      }
    }
  }

  .slogn {
    p {
      -webkit-line-clamp: 5;
    }
  }
}
</style>